<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="products" class="p-datatable-sm" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['codigo', 'producto', 'categoria']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} productos" :loading="loading">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Productos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="codigo" header="Código" :sortable="true"/>
					<Column field="producto" header="Producto" :sortable="true"/>
					<Column field="precio_unitario" header="Precio (Sin IVA)" :sortable="true">
						<template #body="slotProps">
							<!-- <span class="p-column-title">Precio (Sin IVA)</span> -->
							<div class="p-mr-4 p-text-right p-text-bold" style="color: green">
							{{formatCurrency(slotProps.data.precio_unitario)}}
							</div>
						</template>
					</Column>
					<Column field="costo_unitario" header="Costo Unitario" :sortable="true" :style="ver_costo?'' : 'display: none'">
						<template #body="slotProps">
							<div class="p-mr-4 p-text-right" style="color: dimgrey">
							{{formatCurrency(slotProps.data.costo_unitario)}}
							</div>
						</template>
					</Column>
					<Column field="categoria" header="Categoría" :sortable="true"/>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="Producto" :modal="true" class="p-fluid">
					<!-- <img :src="'assets/demo/images/product/' + product.image" :alt="product.image" class="product-image" v-if="product.image" /> -->
					<div class="p-field">
						<label for="producto">Código</label>
						<InputText id="codigo" v-model.trim="product.codigo" @blur="validarProductoDuplicado" required="true" autofocus :class="{'p-invalid': (submitted && !product.codigo) || codigoProductoDuplicado}" />
						<small class="p-error" v-if="submitted && !product.producto">El código de producto es requerido.</small>
						<small class="p-error" v-if="codigoProductoDuplicado">Este código se encuentra asignado a otro producto!</small>
						
					</div>
					<div class="p-field">
						<label for="producto">Producto</label>
						<InputText id="producto" v-model.trim="product.producto" required="true" :class="{'p-invalid': submitted && !product.producto}" />
						<small class="p-error" v-if="submitted && !product.producto">Producto es requerido.</small>
					</div>
					<div class="p-field">
						<label for="descripcion">Description</label>
						<Textarea id="descripcion" v-model="product.descripcion" required="true" rows="3" cols="20" />
					</div>
					<div class="p-field">
						<label for="tipo_producto">Tipo</label>
						<Dropdown v-model="product.id_producto_tipo" :options="lkpTiposProducto" optionLabel="producto_tipo" optionValue="id_producto_tipo" placeholder="Seleccionar..." />
					</div>
					<div class="p-field">
						<label for="categoria_producto">Categoría</label>
						<Dropdown v-model="product.id_categoria" :options="lkpCategorias" optionLabel="categoria" optionValue="id_categoria" placeholder="Seleccionar..." />
					</div>
					<div class="p-field">
						<label for="metodo_costeo">Método Costeo</label>
						<Dropdown v-model="product.id_metodo_costeo" :options="lkpMetodosCosteo" optionLabel="metodo_costeo" optionValue="id_metodo_costeo" placeholder="Seleccionar..." />
					</div>
					
					<div class="p-field">
						<label for="unidad_medida">Unidad de Medida</label>
						<Dropdown v-model="product.id_unidad_medida" :options="lkpUnidadesMedida" optionLabel="unidad" optionValue="id_unidad_medida" placeholder="Seleccionar..." />
					</div>

					<div class="p-field">
						<label for="clasificacion">Clasificación</label>
						<Dropdown v-model="product.id_clasificacion" :options="lkpClasificaciones" optionLabel="clasificacion" optionValue="id_clasificacion" placeholder="Seleccionar..." />
					</div>		

					<div class="p-field">
						<label for="subclasificacion">Subclasificación</label>
						<Dropdown v-model="product.id_subclasificacion" :options="lkpSubclasificaciones" optionLabel="subclasificacion" optionValue="id_subclasificacion" placeholder="Seleccionar..." />
					</div>		

					<div class="p-field">
						<label for="marcas">Marcas</label>
						<Dropdown v-model="product.id_marca" :options="lkpMarcas" optionLabel="marca" optionValue="id_marca" placeholder="Seleccionar..." />
					</div>		
					
					<div class="p-field">
						<label for="almacenamiento">Almacenamiento</label>
						<Dropdown v-model="product.id_almacenamiento_tipo" :options="lkpAlmacenamientosTipo" optionLabel="almacenamiento_tipo" optionValue="id_almacenamiento_tipo" placeholder="Seleccionar..." />
					</div>		
					
					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="precio">Precio Unitario (Sin IVA)</label>
							<InputNumber id="precio" v-model="product.precio_unitario" mode="currency" :currency="moneda" locale="en-US"/>
						</div>
					</div>
					<div v-if="ver_costo" class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="costo">Costo Unitario</label>
							<InputNumber id="costo" v-model="product.costo_unitario" mode="currency" :disabled="!puedeModificarCosto" :currency="moneda" locale="en-US"/>
						</div>
					</div>					
					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" v-if="!codigoProductoDuplicado" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">Esta seguro que desea eliminar el producto<br><b>[{{ product.codigo }}-{{product.producto}}]</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
					</template>
				</Dialog>

				<!-- <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog> -->
			</div>
		</div>
	</div>

</template>

<script>
import InvService from '../service/inv.service';
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			puedeModificarCosto: (this.$store.getters['auth/parametroValor']('PERMITE_EDICION_COSTO_PRODUCTO') == 'S'),
			product: {
					codigo:'',
					id_producto_empresa:''
			},
			moneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,
			codigoProductoDuplicado: false,
			lkpTiposProducto: null,
			lkpCategorias: null,
			lkpMetodosCosteo: null,
			lkpUnidadesMedida: null,
			lkpClasificaciones: null,
			lkpSubclasificaciones: null,
			lkpMarcas: null,
			lkpAlmacenamientosTipo: null,
			selectedProducts: null,
			loading: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			ver_costo: this.$store.getters['auth/tieneAcceso']('IV_COSTO_PRD'),
			submitted: false
		}
	},
	mounted() {
		this.loading = true;

		InvService.getTiposProducto(this.$store.state.auth.currentAppCtx).then(data => this.lkpTiposProducto = data);		
		InvService.getCategoriasProducto(this.$store.state.auth.currentAppCtx).then(data => this.lkpCategorias = data);	
		InvService.getClasificaciones(this.$store.state.auth.currentAppCtx).then(data => this.lkpClasificaciones = data);
		InvService.getSubclasificaciones(this.$store.state.auth.currentAppCtx).then(data => this.lkpSubclasificaciones = data);	
		InvService.getUnidadesMedida(this.$store.state.auth.currentAppCtx).then(data => this.lkpUnidadesMedida = data);
		InvService.getMetodosCosteo(this.$store.state.auth.currentAppCtx).then(data => this.lkpMetodosCosteo = data);				
		InvService.getAlmacenamientoTipos(this.$store.state.auth.currentAppCtx).then(data => this.lkpAlmacenamientosTipo = data);		
		InvService.getProductosMarcas(this.$store.state.auth.currentAppCtx).then(data => this.lkpMarcas = data);			

		InvService.getProductos(this.$store.state.auth.currentAppCtx).then(data => {
			this.products = data;
			this.loading = false;
		}).catch(err => {
			this.loading = false;
			this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
			}
		);		
	},
	methods: {
		async validarProductoDuplicado() {					
			if(this.product.codigo != '') {
				this.loading = true;
				var validacion = await InvService.ExisteCodigoProducto(this.$store.state.auth.currentAppCtx, this.product.codigo);
				this.loading = false;

				this.codigoProductoDuplicado = ((validacion.existe) && (validacion.id_producto_empresa != this.product.id_producto_empresa));
			} else {
				this.codigoProductoDuplicado = false;
		}},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: this.moneda});
			return;
		},
		openNew() {
			this.product = {
				id_producto_empresa: "",
				id_producto: "",
				id_empresa : this.$store.state.auth.currentAppCtx.id_referencia,
				id_categoria: this.lkpCategorias[0].id_categoria,
				id_producto_tipo: this.lkpTiposProducto[0].id_producto_tipo,
				id_metodo_costeo: this.lkpMetodosCosteo[0].id_metodo_costeo,
				id_unidad_medida: "UNIDA",				
				costo_unitario: 0.0,				
				precio_unitario: 0.0,
				pct_descuento: 0.0,	
				pct_utilidad_minima: 0.0,			
				serializado: "N",
				lotificado: "N",
				ensamblado: "N",	
				activo: "S"							
			};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		saveProduct() {			
			this.loading = true;

			InvService.registrarProducto(this.$store.state.auth.currentAppCtx, this.product).then(data => {				
				this.product.id_producto_empresa = data.id_producto_empresa;
				this.product.id_producto = data.id_producto;
				
				let index = this.products.findIndex((obj => obj.id_producto_empresa == this.product.id_producto_empresa));

				if(index == -1) {
					this.products.push(this.product);
				} else {
					this.products[index] = {...this.product};
				}

				this.product = {};
				
				this.submitted = true;
				this.productDialog = false;
				this.loading = false;

			}).catch(data => {
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				this.submitted = true;
				this.productDialog = false;
				this.loading = false;
			});
		},
		editProduct(product) {
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
			this.loading = true;
			InvService.eliminarProducto(this.$store.state.auth.currentAppCtx, this.product).then(() => {
					this.products = this.products.filter(val => val.id_producto_empresa !== this.product.id_producto_empresa);
					this.deleteProductDialog = false;
					this.product = {};
					this.loading = false;
					this.$toast.add({severity:'success', summary: 'Exito', detail: 'El producto ha sido eliminado', life: 3000})
			}
			).catch(
				data => {
					this.deleteProductDialog = false;
					this.product = {};
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			this.deleteProductsDialog = false;
			this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
.costo_unitario {
	color:dimgrey;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
